import styled from 'styled-components';

import { Wrapper as LWrapper } from '@components/Layout/styles';
import { media, spacing, toRem } from '@styles/Theme';

export const Container = styled.div`
	position: relative;
	background: url(${({ bg }) => bg});
	background-size: cover;

	&::before {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: linear-gradient(-150deg, #fff 0%, #000 50%);
		opacity: 0.4;
		content: '';
	}

	h1,
	p {
		position: relative;
		color: #fff;
	}

	h1 {
		margin-bottom: ${spacing(1)};
	}

	p {
		max-width: ${toRem(450)};
	}

	@media (${media.tablet}) {
		h1 {
			margin-bottom: ${spacing(3)};
		}
	}
`;

export const Wrapper = styled(LWrapper)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: ${toRem(300)};
	text-align: center;

	@media (${media.tablet}) {
		min-height: ${toRem(360)};
	}

	@media (${media.desktop}) {
		align-items: flex-start;
		min-height: ${toRem(480)};
		text-align: left;
	}
`;

import styled, { css } from 'styled-components';
import { colors, media, spacing, toRem } from '@styles/Theme';

export const Container = styled.aside`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: ${spacing(3)};
	text-align: left;
	background-color: #f0f0f0;
	border-radius: ${spacing(1)};

	a {
		font-weight: bold;
	}

	@media (${media.desktop}) {
		${({ extended }) =>
			extended
				? css`
						padding: ${spacing(7)};
						margin: 0 auto;
				  `
				: css`
						max-width: ${toRem(320)};
				  `}
	}
`;

export const Item = styled.div`
	&:not(:last-child) {
		margin-bottom: ${spacing(2)};
		padding-bottom: ${spacing(2)};
		border-bottom: ${toRem(1)} dashed ${colors.lightGrey};
	}

	h4 {
		margin-bottom: 0;
		color: ${colors.primary};
	}

	${({ isMobileServices }) =>
		isMobileServices &&
		css`
			@media (${media.mobileOnly}) {
				> div {
					&:first-child {
						margin-bottom: ${spacing(2)};
					}
				}
			}

			@media (${media.tablet}) {
				display: flex;
				justify-content: space-between;

				> div {
					width: calc(100% / 2);

					&:first-child {
						margin-right: ${spacing(7)};
					}
				}
			}
		`}
`;

export const Contacts = styled.div`
	@media (${media.tablet}) {
		display: flex;
	}

	${({ extended }) =>
		!extended &&
		css`
			@media (${media.desktop}) {
				display: block;
			}
		`}
`;

export const Contact = styled.div`
	margin-top: ${spacing(2)};

	@media (${media.tablet}) {
		&:first-child {
			margin-right: ${spacing(7)};
		}
	}

	${({ extended }) =>
		!extended &&
		css`
			@media (${media.desktop}) {
				&:first-child {
					margin-right: 0;
				}
			}
		`}
`;

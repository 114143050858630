import React from 'react';
import PropTypes from 'prop-types';

import { H1, P } from '@styles/Typography';

import * as S from './styles';

function InnerBanner({ bg, description, title }) {
	return (
		<S.Container bg={bg}>
			<S.Wrapper>
				<H1>{title}</H1>
				<P>{description}</P>
			</S.Wrapper>
		</S.Container>
	);
}

InnerBanner.propTypes = {
	bg: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
};

export default InnerBanner;

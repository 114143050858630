import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import CTA from '../index';

function Appointment({ ...props }) {
	const { allCosmicjsCallToActions } = useStaticQuery(graphql`
		{
			allCosmicjsCallToActions(filter: { slug: { eq: "cta-appointment" } }) {
				nodes {
					metadata {
						description
						hat
						title
					}
				}
			}
		}
	`);

	const data = allCosmicjsCallToActions.nodes[0].metadata;

	return <CTA data={data} {...props} />;
}

export default Appointment;

import styled from 'styled-components';

import { boxShadow, media, spacing, toRem } from '@styles/Theme';

import bgHexagonBottomLeft from '@images/bg-hexagon-bottom-left.svg';
import bgHexagonTopRight from '@images/bg-hexagon-top-right.svg';

export const Container = styled.div`
	position: relative;
	border-radius: ${spacing(2)};

	&::before {
		position: absolute;
		top: ${spacing(2)};
		right: ${spacing(-2)};
		bottom: ${spacing(-2)};
		left: ${spacing(2)};
		background-color: #eeeeee;
		border: 1px dashed #dedede;
		border-radius: ${spacing(2)};
		box-shadow: ${boxShadow};
		content: '';
	}
`;

export const Content = styled.div`
	position: relative;

	&::before {
		position: absolute;
		bottom: ${spacing(-3)};
		left: ${spacing(-2)};
		z-index: 30;
		width: ${spacing(6)};
		height: ${spacing(7)};
		background: url(${bgHexagonBottomLeft}) no-repeat;
		background-size: contain;
		content: '';
	}

	&::after {
		position: absolute;
		top: ${spacing(-8)};
		right: ${spacing(-8)};
		z-index: 30;
		width: ${spacing(16)};
		height: ${spacing(20)};
		background: url(${bgHexagonTopRight}) no-repeat;
		background-size: contain;
		transform: scale(0.5);
		content: '';
	}

	@media (${media.tablet}) {
		&::after {
			top: 0;
			right: ${spacing(-7)};
			transform: scale(1);
		}
	}
`;

export const Control = styled.div`
	overflow: hidden;
	background-color: #fff;
	border-radius: ${spacing(2)};
`;

export const Image = styled.img`
	display: block;
	max-width: ${toRem(380)};
`;

export const Text = styled.div`
	padding: ${spacing(5, 2)};

	@media (${media.tablet}) {
		padding: ${spacing(5, 6)};
	}
`;

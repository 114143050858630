import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

export const ShadowCard = ({
	type,
	img,
	title,
	content,
	children,
	...rest
}) => (
	<S.Container {...rest}>
		<div>
			<S.Content>
				<S.Control>
					{type === 'image' ? (
						<S.Image src={img} alt={`${title} - ${content}`} />
					) : (
						<S.Text>{children}</S.Text>
					)}
				</S.Control>
			</S.Content>
		</div>
	</S.Container>
);

ShadowCard.propTypes = {
	type: PropTypes.string,
	img: PropTypes.string,
	title: PropTypes.string,
	content: PropTypes.string,
	children: PropTypes.node,
};

ShadowCard.defaultProps = {
	type: 'content',
	img: null,
	title: undefined,
	content: undefined,
	children: undefined,
};

import styled from 'styled-components';
import { colors, media, spacing } from '@styles/Theme';

export const Container = styled.ul`
	display: flex;
	flex-wrap: wrap;
	margin-top: ${spacing(3)};

	@media (${media.mobileAndTablet}) {
		justify-content: center;
		text-align: center;
	}
`;

export const Item = styled.li`
	margin-right: ${spacing(1)};
	color: ${colors.ligthGrey};

	&:first-child {
		color: ${colors.darkGrey};
		font-weight: bold;
	}

	&:last-child {
		color: ${colors.primary};
		font-weight: bold;
	}
`;

export const Link = styled.a`
	cursor: pointer;
	color: ${colors.darkGrey};

	&:hover {
		color: ${colors.red};
	}
`;

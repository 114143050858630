import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

function BreadCrumb({ crumbs }) {
	return (
		<S.Container>
			<S.Item>Você está em:</S.Item>
			<S.Item>
				<S.Link href={window.location.origin}>Home</S.Link>
			</S.Item>
			{crumbs.map((crumb, index) => {
				if (index < crumbs.length - 1) {
					return (
						<S.Item key={index}>
							»{' '}
							<S.Link href={`${window.location.origin}/${crumb.slug}`}>
								{crumb.title}
							</S.Link>
						</S.Item>
					);
				}
				return <S.Item key={index}>» {crumb.title}</S.Item>;
			})}
		</S.Container>
	);
}

BreadCrumb.propTypes = {
	crumbs: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default BreadCrumb;

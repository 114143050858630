import styled, { css } from 'styled-components';
import { colors, media, spacing, toRem, zIndex } from '@styles/Theme';

import { H5 } from '@styles/Typography';

import { Wrapper as LWrapper } from '@components/Layout/styles';

import stepsMark from '@images/steps-mark.svg';

const hideText = css`
	font-size: 0;
	text-indent: 100%;
`;

const slideNav = css`
	position: absolute;
	top: calc(50% - ${spacing(10)});
	z-index: ${zIndex.floated};
	display: flex !important;
	justify-content: center;
	width: ${spacing(6)};
	height: ${spacing(20)};
	font-size: ${toRem(10)};
	cursor: pointer;

	svg {
		width: ${spacing(4)};
		color: white;
	}

	@media (${media.desktop}) {
		background-color: rgba(0, 0, 0, 0.3);
	}
`;

export const Wrapper = styled(LWrapper)`
	max-width: ${toRem(800)};
	text-align: center;
`;

export const HowToInnerItem = styled.div`
	position: relative;
	padding-left: ${spacing(4)};
`;

export const HowToItem = styled.div`
	position: relative;

	&:not(:last-child) {
		${HowToInnerItem} {
			padding-bottom: ${spacing(4)};

			&::before,
			&::after {
				position: absolute;
				display: block;
				content: '';
			}

			&::before {
				left: 0;
				width: 2px;
				height: 100%;
				border-left: 2px dashed #ccc;
			}

			&::after {
				top: 0;
				left: 0;
				width: 2px;
				height: 100%;
				background: transparent;
				background: linear-gradient(
					0deg,
					rgba(255, 255, 255, 1) 0%,
					rgba(255, 255, 255, 0) 49%,
					rgba(255, 255, 255, 1) 100%
				);
			}
		}
	}

	@media (${media.tablet}) {
		padding-left: ${spacing(12)};

		&::before {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: ${spacing(6)};
			height: ${spacing(6)};
			background: url(${({ icon }) => icon}) no-repeat;
			background-size: contain;
			content: '';
		}
	}
`;

export const HowToTitle = styled(H5)`
	position: relative;

	&::before {
		position: absolute;
		top: ${spacing(0.5)};
		left: 0;
		z-index: ${zIndex.floated};
		width: ${spacing(2)};
		height: ${spacing(2)};
		background: url(${stepsMark}) no-repeat;
		background-size: cover;
		transform: translateX(calc(${spacing(-6)} + 10px));
		filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
		content: '';
	}
`;

export const ContentBox = styled.div`
	position: relative;
	padding-bottom: ${spacing(6)};
	text-align: center;

	&::before {
		position: absolute;
		bottom: 0;
		left: 50%;
		display: block;
		width: ${spacing(8)};
		height: ${spacing(0.5)};
		background-color: ${colors.primary};
		content: '';
	}

	@media (${media.mobileAndTablet}) {
		margin-bottom: ${spacing(6)};

		&::before {
			transform: translateX(${spacing(-4)});
		}
	}

	@media (${media.desktop}) {
		max-width: ${toRem(430)};
		text-align: left;

		&::before {
			left: 0;
		}
	}
`;

export const Block = styled.div`
	&:first-child {
		margin-bottom: ${spacing(8)};
	}

	@media (${media.mobileAndTablet}) {
		max-width: ${toRem(570)};
		margin: 0 auto;
	}

	@media (${media.desktop}) {
		display: flex;
		align-items: center;
		justify-content: space-between;

		> div {
			width: calc(100% / 2 - ${spacing(4)});
		}

		&:nth-child(even) {
			${ContentBox} {
				order: 1;
			}
		}
	}
`;

export const Units = styled.div`
	position: relative;
	margin-bottom: ${spacing(2)};
	padding-bottom: ${spacing(2)};
	text-align: center;
	border-bottom: ${toRem(2)} dashed ${colors.lightGrey};

	.slick-dots {
		position: absolute;
		top: calc(100% - ${spacing(10)});
		display: flex !important;
		justify-content: center;
		width: 100%;

		li {
			button {
				${hideText}
				position: relative;
				display: block;
				width: ${spacing(2)};
				height: ${spacing(2)};
				background-color: transparent;
				border: none;
				outline: none;

				&::after {
					position: absolute;
					top: calc(50% - ${spacing(0.5)});
					left: calc(50% - ${spacing(0.5)});
					display: block;
					width: ${spacing(1)};
					height: ${spacing(1)};
					background-color: #fff;
					border-radius: 100%;
					transition: transform 0.2s linear;
					content: '';
				}
			}

			&.slick-active {
				button::after {
					transform: scale(1.5);
				}
			}
		}
	}
`;

export const SliderContent = styled.figure``;

export const Caption = styled.figcaption`
	margin-top: ${spacing(2)};
`;

export const Image = styled.img`
	display: block;
	width: 100%;
	border-radius: ${spacing(4)};
`;

export const Prev = styled.div`
	${slideNav}
	left: 0;
	border-radius: ${spacing(0, 4, 4, 0)};
`;

export const Next = styled.div`
	${slideNav}
	right: 0;
	border-radius: ${spacing(4, 0, 0, 4)};
`;

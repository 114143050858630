import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Slider from 'react-slick';
import '@styles/slider.sass';

import { H3, P } from '@styles/Typography';

import { ChevronLeftIcon, ChevronRightIcon } from '@components/Icons';

import { Section, Wrapper } from '@components/Layout/styles';

import SEO from '@components/seo';
import Breadcrumb from '@components/Breadcrumb';
import CTA from '@components/CTAS/Appointment';
import InnerBanner from '@components/InnerBanner';
import Sidebar from '@components/Sidebars/Main';
import TitleSection from '@components/TitleSection';
import Link from '@components/Link';
import { ShadowCard } from '@components/Cards';

import * as S from './_styles';

const settings = {
	adaptiveHeight: true,
	dots: true,
	fade: true,
	infinite: false,
	speed: 500,
	draggable: true,
	prevArrow: (
		<S.Prev>
			<ChevronLeftIcon />
		</S.Prev>
	),
	nextArrow: (
		<S.Next>
			<ChevronRightIcon />
		</S.Next>
	),
};

const ComoFuncionaPage = () => {
	const {
		appointments,
		highlight,
		meta,
		pageData,
		treatmentInTheUnit,
		units,
	} = useStaticQuery(graphql`
		{
			meta: allCosmicjsMeta(filter: { slug: { eq: "meta-about" } }) {
				nodes {
					metadata {
						title
						description
						cover {
							imgix_url
						}
					}
				}
			}

			pageData: allCosmicjsPages(filter: { slug: { eq: "about" } }) {
				nodes {
					metadata {
						description
						title
						banner {
							imgix_url
						}
					}
				}
			}

			highlight: allCosmicjsAbout(filter: { slug: { eq: "highlight" } }) {
				nodes {
					metadata {
						description
						title
						hat
					}
				}
			}

			appointments: allCosmicjsAbout(filter: { slug: { eq: "appointments" } }) {
				nodes {
					metadata {
						description
						title
						items {
							description
							icon {
								imgix_url
							}
							title
						}
					}
				}
			}

			treatmentInTheUnit: allCosmicjsAbout(
				filter: { slug: { eq: "treatment-in-the-unit" } }
			) {
				nodes {
					metadata {
						description
						title
						items {
							description
							icon {
								imgix_url
							}
							title
						}
					}
				}
			}

			units: allCosmicjsAbout(filter: { slug: { eq: "units" } }) {
				nodes {
					metadata {
						hat
						title
						items {
							alt
							caption
							image {
								imgix_url
							}
						}
					}
				}
			}
		}
	`);

	const {
		metadata: { title: pageTitle, description: pageDescription, banner },
	} = pageData.nodes[0];

	const {
		description: highlightDescription,
		title: highlightTitle,
		hat: highlightHat,
	} = highlight.nodes[0].metadata;

	const {
		description: appointmentsDescription,
		title: appointmentsTitle,
		items: appointmentsItems,
	} = appointments.nodes[0].metadata;

	const {
		description: treatmentInTheUnitDescription,
		title: treatmentInTheUnitTitle,
		items: treatmentInTheUnitItems,
	} = treatmentInTheUnit.nodes[0].metadata;

	const {
		hat: unitsHat,
		title: unitsTitle,
		items: unitsItems,
	} = units.nodes[0].metadata;

	const {
		metadata: { title: metaTitle, description: metaDescription, cover },
	} = meta.nodes[0];

	return (
		<>
			<SEO
				title={metaTitle || 'Como funciona'}
				description={metaDescription}
				cover={cover.imgix_url}
			/>

			<InnerBanner
				title={pageTitle}
				description={pageDescription}
				bg={banner.imgix_url}
			/>
			<Wrapper>
				<Breadcrumb
					crumbs={[{ title: 'Como funciona', slug: 'como-funciona' }]}
				/>
			</Wrapper>

			<Section>
				<Wrapper>
					<TitleSection
						hat={highlightHat}
						title={highlightTitle}
						content={highlightDescription}
					/>
				</Wrapper>
			</Section>

			<Section>
				<Wrapper>
					<S.Block>
						<S.ContentBox>
							<H3>{appointmentsTitle}</H3>
							<P>{appointmentsDescription}</P>
						</S.ContentBox>

						<ShadowCard>
							{appointmentsItems.map(
								({ title, icon: { imgix_url: icon }, description }, index) => (
									<S.HowToItem key={index} icon={icon}>
										<S.HowToInnerItem>
											<S.HowToTitle>{title}</S.HowToTitle>
											<div dangerouslySetInnerHTML={{ __html: description }} />
										</S.HowToInnerItem>
									</S.HowToItem>
								)
							)}
						</ShadowCard>
					</S.Block>

					<S.Block>
						<S.ContentBox>
							<H3>{treatmentInTheUnitTitle}</H3>
							<P>{treatmentInTheUnitDescription}</P>
						</S.ContentBox>

						<ShadowCard>
							{treatmentInTheUnitItems.map(
								({ title, icon: { imgix_url: icon }, description }, index) => (
									<S.HowToItem key={index} icon={icon}>
										<S.HowToInnerItem>
											<S.HowToTitle>{title}</S.HowToTitle>
											<div dangerouslySetInnerHTML={{ __html: description }} />
										</S.HowToInnerItem>
									</S.HowToItem>
								)
							)}
						</ShadowCard>
					</S.Block>
				</Wrapper>
			</Section>

			<Section bgColor="white">
				<Wrapper>
					<CTA noSpacing />
				</Wrapper>
			</Section>

			<Section>
				<S.Wrapper>
					<TitleSection hat={unitsHat} title={unitsTitle} />

					<S.Units>
						<Slider {...settings}>
							{unitsItems.map(
								({ alt, caption, image: { imgix_url: url } }, index) => (
									<S.SliderContent key={index}>
										<S.Image src={url} alt={alt} />
										<S.Caption>{caption}</S.Caption>
									</S.SliderContent>
								)
							)}
						</Slider>
					</S.Units>

					<Link to="/unidades" label="Conheça nossas unidades" withIcon />
				</S.Wrapper>
			</Section>

			<Section bgImg="https://imgix.cosmicjs.com/5f4b92e0-e254-11ea-ad85-b3e5c5f45697-726063e0-d39c-11ea-a151-53cec96789fd-national-cancer-institute-BxXgTQEw1M4-unsplash-1.jpg?q=&w=1920&h=600&fit=crop">
				<Wrapper>
					<Sidebar extended />
				</Wrapper>
			</Section>
		</>
	);
};

export default ComoFuncionaPage;

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import useGlobal from '@hooks/useGlobal';

import Link from '@components/Link';
import { WhatsappIcon, PhoneIcon } from '@components/Icons';

import { H4, P } from '@styles/Typography';
import * as S from './styles';

const Main = ({ extended }) => {
	const [state] = useGlobal();
	const {
		contacts: { phone, whatsapp },
	} = state;

	const { allCosmicjsSidebars } = useStaticQuery(graphql`
		{
			allCosmicjsSidebars {
				nodes {
					metadata {
						attendance {
							title
							description
						}
						contacts {
							title
							description
						}
						mobile_services {
							title
							description
							region_title
							region_description
						}
					}
				}
			}
		}
	`);

	const {
		metadata: { attendance, contacts, mobile_services },
	} = allCosmicjsSidebars.nodes[0];

	const isFaq = /perguntas-frequentes/.test(window.location.pathname);
	const isMobileServices = /atendimento-movel/.test(window.location.pathname);

	return (
		<>
			<S.Container extended={extended}>
				{!isFaq && (
					<S.Item>
						<H4>Perguntas frequentes</H4>
						<P>
							Tire dúvidas rápidas sobre os procedimentos{' '}
							<Link to="/perguntas-frequentes" label="clicando aqui" />
						</P>
					</S.Item>
				)}

				<S.Item>
					<H4>{contacts.title}</H4>
					<div dangerouslySetInnerHTML={{ __html: contacts.description }} />

					<S.Contacts extended={extended}>
						{whatsapp && (
							<S.Contact extended={extended}>
								<Link
									to={whatsapp.link}
									label={whatsapp.label}
									prefix={<WhatsappIcon />}
									external
								/>
								<p>{whatsapp.hour}</p>
							</S.Contact>
						)}

						{phone && (
							<S.Contact extended={extended}>
								<Link
									to={phone.link}
									label={phone.label}
									prefix={<PhoneIcon />}
									external
								/>
								<p>{phone.hour}</p>
							</S.Contact>
						)}
					</S.Contacts>
				</S.Item>

				<S.Item isMobileServices={isMobileServices}>
					<div>
						<H4>
							{isMobileServices ? mobile_services.title : attendance.title}
						</H4>
						<div
							dangerouslySetInnerHTML={{
								__html: isMobileServices
									? mobile_services.description
									: attendance.description,
							}}
						/>
					</div>

					{isMobileServices && (
						<div>
							<H4>{mobile_services.region_title}</H4>
							<div
								dangerouslySetInnerHTML={{
									__html: mobile_services.region_description,
								}}
							/>
						</div>
					)}
				</S.Item>
			</S.Container>
		</>
	);
};

Main.propTypes = {
	extended: PropTypes.bool,
};

Main.defaultProps = {
	extended: false,
};

export default Main;
